@import "/src/styles/rebuild/global.scss";
@import "/src/_includes/sections/download-section/styles/index.scss";

.section-404{
    background-image: 
    linear-gradient(222.97deg, #003D84 7.79%, #113355 60.29%);
    
    min-height: 100vh;
    padding-top: 13vh;
    .title-1{
        color: #fff;
        font-size: 90px;
        margin: 1em 0;
        // ------------media-------------
        @include w($sm){
            font-size: 45px;
        }
    }
    .container{
        padding: 13vh 0;
        background-image: url(/src/images/404.svg);
        background-repeat:no-repeat;
        background-position: 50% 0;
        background-size:contain;
        text-align: center;
    }
}