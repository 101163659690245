*,
*:before,
*:after {
    box-sizing: inherit;
}
* {
    padding: 0;
    margin: 0;
}
html {
    box-sizing: border-box;
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
    list-style-type: none;
}
audio,
video {
    display: block;
}
picture img {
    max-width: 100%;
}
// picture img[width] {
//     width: auto;
// }
picture img[width][height] {
    height: auto;
}
img {
    display: block;
    border: none;
    max-width: 100%;
    height: auto;
}

iframe {
    border: none;
}

pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: inherit;
}

a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
}

abbr {
    border: none;
    text-decoration: none;
}

b,
strong {
    font-weight: inherit;
}

i,
em {
    font-style: inherit;
}

dfn {
    font-style: inherit;
}

mark {
    background-color: transparent;
    color: inherit;
}

small {
    font-size: inherit;
}

sub,
sup {
    position: relative;
    vertical-align: baseline;
    font-size: inherit;
    line-height: 0;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    font: inherit; /* По дефолту, шрифтовые свойства, для этих элементов не наследуются */
    color: inherit;
    letter-spacing: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-align: left;
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    cursor: pointer;
    -webkit-appearance: none;
}

textarea {
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

[type="search"] {
    outline: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity 0.3s;
}

::-moz-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity 0.3s;
}

:-moz-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity 0.3s;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity 0.3s;
}

:focus::-webkit-input-placeholder {
    opacity: 0;
}

:focus::-moz-placeholder {
    opacity: 0;
}

:focus:-moz-placeholder {
    opacity: 0;
}

:focus:-ms-input-placeholder {
    opacity: 0;
}
a {
    outline: none;
}
button {
    outline: none;
}
input:focus {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}
textarea {
    outline: none;
}